<template>
    <div>
        <div v-loading="loading">
            <img
                class="cp ml_auto"
                src="/img/icons/download.svg"
                alt=""
                @click="downloadDocument"
            >
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'ActDownloader',
    props: ['scope', 'component'],
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        ...mapGetters('Bills', ['billsData']),
    },
    methods: {
        ...mapActions('Bills', ['getActFile']),
        downloadDocument() {
            if (!this.billsData[this.component].data[this.scope.$index].fileUrl) {
                this.loading = true;

                this.getActFile({ type: this.component, ind: this.scope.$index, row: this.scope.row })
                    .then(() => {
                        this.savePdf({ ind: this.scope.$index, name: this.scope.row.number });
                    })
                    .catch(() => {
                        this.$notify.error({ title: 'Ошибка', message: 'Что-то пошло не так' });
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } else {
                this.savePdf({ ind: this.scope.$index, name: this.scope.row.number });
            }
        },
        savePdf(e) {
            const link = document.createElement('a');
            link.href = this.billsData[this.component].data[e.ind].fileUrl;
            link.download = e.name;
            link.click();
        },
    },
};
</script>

<style scoped>
::v-deep .el-loading-spinner .circular {
    height: 24px;
    width: 24px;
}

::v-deep .el-loading-spinner {
    margin-left: 5px;
    margin-top: -15px;
}
</style>
