<template>
    <div>
        <div class="flex flex__jc-fe mb_32">
            <el-button class="btn_red btn_40" @click="setEditMode">
                Создать новую версию
            </el-button>
        </div>

        <requisites-form v-if="singleRequisite" :requisites="requisites" :disabled="true" />

        <el-collapse v-else v-model="activeNames">
            <el-collapse-item
                v-for="item in requisites"
                :key="item.id"
                :title="`Версия ${item.version}`"
                :name="item.id"
            >
                <template slot="title">
                    <div class="flex flex__ai-c flex__jc-sb w-100">
                        <p class="text_16 text_dark text_b">
                            Версия {{ item.version }}
                        </p>

                        <p class="text_13 text_gray text_n ml_auto mr_16">
                            {{ getPeriodText(item) }}
                        </p>
                    </div>
                </template>
                <requisites-form :requisites="item" :disabled="true" />
            </el-collapse-item>
        </el-collapse>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RequisitesForm from '@/views/requisites/requisites-form.vue';

export default {
    name: 'RequisitesList',
    components: { RequisitesForm },
    data() {
        return {
            activeNames: [],
        };
    },
    computed: {
        ...mapGetters('Requisites', ['requisitesData']),
        singleRequisite() {
            return this.requisitesData.list?.length === 1;
        },
        requisites() {
            return this.singleRequisite ? this.requisitesData.list?.[0] : this.requisitesData.list;
        },
    },
    methods: {
        setEditMode() {
            this.$emit('setEditMode');
        },
        getPeriodText(e) {
            return e.period_to ? `Действовала c ${e.period_from} по ${e.period_to}` : `Действует с ${e.period_from}`;
        },
    },
};
</script>

<style scoped>

</style>
