<template>
    <div>
        <leads-table :component="component" />
    </div>
</template>

<script>
import LeadsTable from '@/views/leads/parts/leads-table.vue';

export default {
    name: 'LeadsCreated',
    components: { LeadsTable },
    props: ['component'],
};
</script>

<style scoped>

</style>
