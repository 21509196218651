<template>
    <div id="app">
        <head-title :title="$route.meta.title || ''" />

        <component :is="component">
            <router-view />
        </component>
    </div>
</template>

<script>
import WhiteBlankLayout from '@/layouts/WhiteBlankLayout.vue';
import MainLayout from '@/layouts/MainLayout.vue';
import HeadTitle from '@/components/UI/head-title.vue';

export default {
    components: { HeadTitle, MainLayout, WhiteBlankLayout },
    computed: {
        component() {
            return this.$route.meta?.layout ? this.$route.meta?.layout : 'MainLayout';
        },
    },
};
</script>

<style lang="scss">
</style>
