<template>
    <div />
</template>

<script>
export default {
    name: 'HeadTitle',
    props: {
        title: {
            type: String,
            default: '',
        },
    },
    watch: {
        title(val) {
            document.title = val;
        },
    },
    created() {
        document.title = this.title;
    },
};
</script>
