<template>
    <div>
        <div v-if="!leadsData[component].loading || leadsData[component].data" v-loading="leadsData[component].loading">
            <el-table
                ref="table"
                :data="leadsData[component].data"
                class="mp__table mp__table-header-dark"
                style="width: 100%"
            >
                <el-table-column label="Статус" width="65px">
                    <template slot-scope="scope">
                        <el-tooltip
                            :content="scope.row.status_text_for_agent"
                            :disabled="!scope.row.status_text_for_agent"
                            :visible-arrow="false"
                            effect="dark"
                            placement="top"
                            popper-class="el-tooltip__200"
                            transition="el-fade-in"
                        >
                            <div class="flex flex__jc-c">
                                <img
                                    :src="`/img/icons/status/status-${getStatusIcon(scope.row?.lead_status)}.svg`"
                                    alt=""
                                >
                            </div>
                        </el-tooltip>
                    </template>
                </el-table-column>

                <el-table-column
                    label="Компания/ФИО"
                >
                    <template slot-scope="scope">
                        <p class="text_12 text_dark">
                            {{ scope.row.name }}
                        </p>
                    </template>
                </el-table-column>

                <el-table-column
                    label="ИНН"
                >
                    <template slot-scope="scope">
                        <p class="text_12 text_dark">
                            {{ scope.row.lead_inn }}
                        </p>
                    </template>
                </el-table-column>

                <el-table-column
                    label="Дата лида"
                >
                    <template slot-scope="scope">
                        <p class="text_12 text_dark">
                            {{ scope.row.created_date }}
                        </p>
                    </template>
                </el-table-column>

                <el-table-column
                    label="Дата выполнения условий"
                >
                    <template slot-scope="scope">
                        <p class="text_12 text_dark">
                            {{ scope.row.completed_date }}
                        </p>
                    </template>
                </el-table-column>

                <el-table-column
                    label="Продукт"
                >
                    <template slot-scope="scope">
                        <p class="text_12 text_dark">
                            {{ scope.row.product_name }}
                        </p>
                    </template>
                </el-table-column>

                <el-table-column
                    label="Вознаграждение"
                >
                    <template slot-scope="scope">
                        <p class="text_12 text_dark">
                            {{ getMoneyTitle(scope.row.reward) }}
                        </p>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination
                :current-page.sync="pagination.page"
                :page-size.sync="pagination.limit"
                :page-sizes="[5, 10, 50, 100]"
                :total="leadsData[component].total"
                class="mt_16"
                layout="total, sizes, slot, prev, next"
                style="text-align: right"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            >
                <template>
                    <span class="text_13 text_dark text_n">
                        {{ pagination.limit * (pagination.page - 1) + 1 }} -
                        {{
                            pagination.limit * pagination.page < leadsData[component].total ? pagination.limit * pagination.page : leadsData[component].total
                        }}
                        из {{ leadsData[component].total }}
                    </span>
                </template>
            </el-pagination>
        </div>

        <el-skeleton
            v-if="leadsData[component].loading && !leadsData[component].data"
            :count="1"
            :rows="6"
            animated
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moneyFormat from '@/services/moneyFormat.js';

export default {
    name: 'LeadsTable',
    props: ['component'],
    data() {
        return {
            pagination: {
                page: 1,
                limit: 5,
            },
            urls: {
                leadsInProgress: 'list-in-progress',
                leadsCreated: 'list-created',
                leadsCompleted: 'list-completed',
                leadsRejected: 'list-rejected',
            },
            period: null,
        };
    },
    computed: {
        ...mapGetters('Leads', ['leadsData']),
        periodSum() {
            return `${this.leadsData.period.from}${this.leadsData.period.to}`;
        },
    },
    watch: {
        'leadsData.period': {
            handler() {
                if (this.period !== this.periodSum) {
                    this.handleSizeChange();
                }
            },
            deep: true,
        },
    },
    created() {
        this.getData();
    },
    methods: {
        ...mapActions('Leads', ['getTableData']),
        handleSizeChange() {
            this.pagination.page = 1;
            this.getData();
        },
        handleCurrentChange() {
            this.getData();
        },
        getData() {
            const params = {
                type: this.component,
                url: this.urls[this.component],
                page: this.pagination.page,
                limit: this.pagination.limit,
            };
            this.getTableData(params)
                .then(() => {
                    this.period = `${this.leadsData.period.from}${this.leadsData.period.to}`;
                });
        },
        getStatusIcon(status) {
            let icon = '';

            if (status === 1) {
                icon = 'green';
            }

            if (status === 2) {
                icon = 'red';
            }

            if (status === 3) {
                icon = 'gray';
            }

            return icon;
        },
        getMoneyTitle(e) {
            return moneyFormat(e);
        },
    },
};
</script>

<style scoped>
::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
    background-color: #FFFFFF;
}
</style>
