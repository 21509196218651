<template>
    <div class="flex flex__ai-c">
        <img class="mr_24" style="width: 24px" :src="`/img/icons/${item.icon}.svg`" alt="">

        <p class="text_13 text_dark mr_24">
            {{ item.name }}
        </p>

        <a class="text_13" target="_blank" :href="item.url">
            {{ item.url }}
        </a>

        <el-tooltip
            v-model="showCopyTooltip"
            :manual="true"
            :visible-arrow="false"
            content="Скопировано"
            effect="dark"
            placement="top"
            transition="el-fade-in"
        >
            <img
                style="width: 16px"
                src="/img/icons/copy.svg"
                class="ml_auto cp"
                alt=""
                @click="copy(item.url)"
            >
        </el-tooltip>
    </div>
</template>

<script>
export default {
    name: 'DocsListItem',
    props: ['item'],
    data() {
        return {
            showCopyTooltip: false,
        };
    },
    methods: {
        copy(text) {
            navigator.clipboard.writeText(text);
            this.showCopyTooltip = true;

            setTimeout(() => {
                this.showCopyTooltip = false;
            }, 1500);
        },
    },
};
</script>

<style scoped lang="scss">
.docs__list {
    > div {
        padding: 20px 10px;
        border-bottom: 1px solid #EBEEF5;
    }

    p {
        width: 100%;
        max-width: 300px;
    }
}
</style>
