/* eslint-disable no-shadow */

import axios from 'axios';
import router from '@/router';

const state = {
    authData: {
        isAuthenticated: !!localStorage.getItem('token'),
        loginSuccess: false,
        loginError: false,
        codeError: false,
    },
    userData: {
        user: null,
        tariff: null,
        loading: false,
    },
};

const getters = {
    authData: () => state.authData,
    userData: () => state.userData,
};

const actions = {
    /**
     *  авторизация
     */
    login({ commit, dispatch }, params) {
        return axios.post('/api/login', params)
            .then((resp) => {
                commit('login', true);
                return Promise.resolve();
            })
            .catch((err) => {
                commit('login', false);
            });
    },

    /**
   *  Проверка смс-кода
   */
    checkCode({ commit, dispatch }, params) {
        return axios.post('/api/check-code', params)
            .then((resp) => {
                commit('checkCode', resp.data);
                commit('refreshLoginSuccess');
                return Promise.resolve();
            })
            .catch((err) => {
                commit('checkCodeError');
                return Promise.reject(err);
            });
    },

    /**
     *  Получение пользователя
     */
    getUser({ commit }) {
        commit('changeLoading', 'userData');

        return axios.get('/api/me')
            .then((resp) => {
                commit('getUser', resp.data);
            })
            .catch((err) => {
                // Сбрасываем сессию при получении 401
                state.authData.isAuthenticated = false;
                localStorage.removeItem('token');
                delete axios.defaults.headers.common['api-token'];
                router.go(0);

                return Promise.reject(err);
            })
            .finally(() => {
                commit('changeLoading', 'userData');
            });
    },

    /**
     *  сброс пароля, на почту падает сгенерированный
     */
    resetPassword({ commit }, params) {
        return axios.post('/api/reset-password', params)
            .then((resp) => Promise.resolve(resp))
            .catch((err) => Promise.reject(err.response?.data?.error));
    },

    /**
     *  сброс пароля, на почту падает сгенерированный
     */
    changePassword({ commit }, params) {
        return axios.post('/api/change-password', params)
            .then((resp) => Promise.resolve(resp))
            .catch((err) => Promise.reject(err));
    },

    /**
     *  логаут
     */
    logout({ commit }) {
        return axios.post('/api/logout')
            .then((resp) => {
                commit('logout');
            })
            .catch((err) => {
                console.log(err);
            });
    },

    /**
     *  Отправка фидбэка
     */
    sendFeedback({ commit }, params) {
        return axios.post('/api/feedback/create', params)
            .then((resp) => resp)
            .catch((err) => {
                console.log(err);
            });
    },
};

const mutations = {
    changeLoading(state, payload) {
        state[payload].loading = !state[payload].loading;
    },
    login(state, payload) {
        if (payload) {
            state.authData.loginSuccess = true;
            state.authData.loginError = false;
            state.authData.codeError = false;
        } else {
            state.authData.loginError = true;
        }
    },
    checkCode(state, payload) {
        if (payload?.token) {
            axios.defaults.headers.common['api-token'] = payload.token;
            localStorage.setItem('token', payload.token);
            state.authData.isAuthenticated = true;
            state.authData.codeError = false;
        }
    },
    checkCodeError(state) {
        state.authData.codeError = true;
    },
    refreshLoginSuccess(state) {
        state.authData.loginSuccess = false;
    },
    getUser(state, payload) {
        state.userData.user = payload.user;
        state.userData.tariff = payload.tariffs?.[0];
    },
    logout(state) {
        state.authData.isAuthenticated = false;
        localStorage.removeItem('token');
        delete axios.defaults.headers.common['api-token'];
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
