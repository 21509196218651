/* eslint-disable no-shadow */

import axios from 'axios';
import dayjs from 'dayjs';

const state = {
    leadsData: {
        leadsInProgress: {
            data: null,
            total: 0,
            loading: false,
        },
        leadsCompleted: {
            data: null,
            total: 0,
            loading: false,
        },
        leadsCreated: {
            data: null,
            total: 0,
            loading: false,
        },
        leadsRejected: {
            data: null,
            total: 0,
            loading: false,
        },
        period: {
            from: null,
            to: null,
        },
        summary: {
            data: null,
            loading: false,
        },
    },
};

const getters = {
    leadsData: () => state.leadsData,
};

const actions = {
    /**
     *  Получение данных для таблицы
     */
    getTableData({ commit, state }, params) {
        commit('changeLoading', params.type);

        return axios.get(`/api/leads/${params.url}?page=${params.page}&limit=${params.limit}&from=${state.leadsData.period.from}&to=${state.leadsData.period.to}`)
            .then((resp) => {
                commit('getTableData', { response: resp.data, type: params.type });
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                commit('changeLoading', params.type);
            });
    },

    getSummary({ commit, state }) {
        commit('changeLoading', 'summary');

        return axios.get(`/api/leads/summary?from=${state.leadsData.period.from}&to=${state.leadsData.period.to}`)
            .then((resp) => {
                commit('getSummary', resp.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                commit('changeLoading', 'summary');
            });
    },
    setPeriod({ commit, dispatch }, period) {
        commit('setPeriod', period);
        dispatch('getSummary');
    },
    createLead({ commit, state }, params) {
        return axios.post('/api/leads/send', params)
            .then((resp) => {
                console.log(111, resp);
                return Promise.resolve();
            })
            .catch((err) => Promise.reject(err));
    },
};

const mutations = {
    changeLoading(state, payload) {
        state.leadsData[payload].loading = !state.leadsData[payload].loading;
    },

    getTableData(state, payload) {
        state.leadsData[payload.type].data = payload.response.data;
        state.leadsData[payload.type].total = payload.response.pagination.total;
    },

    getSummary(state, payload) {
        state.leadsData.summary.data = payload;
    },
    setPeriod(state, payload) {
        /* YYYY-MM-DDTHH:mm:ss */
        if (!payload) {
            state.leadsData.period.from = dayjs().set('days', -30).format('YYYY-MM-DDTHH:mm:ss');
            state.leadsData.period.to = dayjs().format('YYYY-MM-DDTHH:mm:ss');
        } else {
            state.leadsData.period.from = dayjs(payload[0]).format('YYYY-MM-DDTHH:mm:ss');
            state.leadsData.period.to = dayjs(payload[1]).format('YYYY-MM-DDTHH:mm:ss');
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
