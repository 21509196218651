import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import LoginView from '@/views/LoginView.vue';
import LeadsView from '@/views/LeadsView.vue';
import BillsView from '@/views/BillsView.vue';
import DocsView from '@/views/DocsView.vue';
import RequisitesView from '@/views/RequisitesView.vue';

Vue.use(VueRouter);

const ifNotAuthenticated = (to, from, next) => {
    if (!store.getters['User/authData'].isAuthenticated) {
        next();
    } else {
        next('/main');
    }
};

const ifAuthenticated = (to, from, next) => {
    if (store.getters['User/authData'].isAuthenticated) {
        next();
    } else {
        next('/login');
    }
};

const routes = [
    {
        path: '/main',
        name: 'main',
        meta: {
            title: 'ЛК Агента - Заявки',
        },
        component: LeadsView,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/bills',
        name: 'bills',
        meta: {
            title: 'ЛК Агента - Счета и акты',
        },
        component: BillsView,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/docs',
        name: 'docs',
        meta: {
            title: 'ЛК Агента - Материалы',
        },
        component: DocsView,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/requisites',
        name: 'requisites',
        meta: {
            title: 'ЛК Агента - Реквизиты',
        },
        component: RequisitesView,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            title: 'Добро пожаловать в личный кабинет Агента (ПАО Росбанк)',
            layout: 'WhiteBlankLayout',
        },
        component: LoginView,
        beforeEnter: ifNotAuthenticated,
    },
    {
        path: '*', redirect: '/main',
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
