<template>
    <div class="el-input relative">
        <the-mask
            ref="input"
            v-model="valueState"
            :mask="mask"
            :placeholder="placeholder"
            class="el-input__inner"
            :disabled="disabled"
            @paste.native.prevent="paste"
        />

        <img
            v-if="valueState && !disabled"
            alt=""
            class="pmi-close-button cp"
            src="/img/icons/close_dark.svg"
            @click="clean"
        >
    </div>
</template>

<script>
import { TheMask } from 'vue-the-mask';

export default {
    name: 'MpPhoneMaskedInput',
    components: { TheMask },
    props: {
        value: {
            type: String,
            default: '',
        },
        mask: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            valueState: this.value || '',
        };
    },
    watch: {
        value(val) {
            this.valueState = val;
        },
        valueState() {
            this.$emit('input', this.valueState);
        },
    },
    methods: {
        paste() {
            navigator.clipboard.readText()
                .then((res) => {
                    const text = res.replace(/\D+/gm, '');

                    if (text[0] === '8') {
                        this.valueState = text.substring(1);
                    } else {
                        this.valueState = text;
                    }
                })
                .catch((err) => {
                    console.log('Something went wrong', err);
                });
        },
        clean() {
            this.valueState = '+7';
            this.$emit('input', this.valueState);
            this.$refs.input.$vnode.elm.focus();
        },
    },
};
</script>

<style lang="scss" scoped>
.pmi-close-button {
    position: absolute;
    top: 50%;
    bottom: 50%;
    right: 12px;
    margin: auto 0;
    display: none;
}

.el-input:hover {
    .pmi-close-button {
        display: block;
    }
}
</style>
