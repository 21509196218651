<template>
    <div>
        <slot />
    </div>
</template>

<script>
export default {
    name: 'WhiteBlankLayout',
    data() {
        return {
        };
    },
};
</script>

<style scoped>

</style>
