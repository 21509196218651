import Vue from 'vue';
import Vuex from 'vuex';
import User from '@/store/modules/User.js';
import Bills from '@/store/modules/Bills.js';
import Leads from '@/store/modules/Leads.js';
import Requisites from '@/store/modules/Requisites.js';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        User,
        Bills,
        Leads,
        Requisites,
    },
});
