<template>
    <div class="top-bar">
        <div class="top-bar__inner">
            <div class="flex">
                <router-link
                    v-for="(route, ind) in routes"
                    :key="ind"
                    class="top-bar__link text_15 text_b"
                    :to="route.path"
                    exact
                >
                    {{ route.name }}
                </router-link>

                <feedback-drawer />
            </div>

            <div class="flex flex__ai-c">
                <tariffs-drawer v-if="userData.tariff" />

                <top-bar-user-controls />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TariffsDrawer from '@/components/top-bar/tariffs-drawer.vue';
import FeedbackDrawer from '@/components/top-bar/feedback-drawer.vue';
import TopBarUserControls from '@/components/top-bar/top-bar-user-controls.vue';

export default {
    name: 'TopBar',
    components: { TopBarUserControls, FeedbackDrawer, TariffsDrawer },
    data() {
        return {
            routes: [
                {
                    name: 'Заявки',
                    path: '/main',
                },
                {
                    name: 'Материалы',
                    path: '/docs',
                },
                {
                    name: 'Счета и акты',
                    path: '/bills',
                },
                {
                    name: 'Реквизиты',
                    path: '/requisites',
                },
            ],
        };
    },
    computed: {
        ...mapGetters('User', ['userData']),
    },
};
</script>

<style scoped>

</style>
