/* eslint-disable no-shadow */

import axios from 'axios';

const state = {
    billsData: {
        actsInProgress: {
            data: null,
            total: 0,
            loading: false,
        },
        actsCompleted: {
            data: null,
            total: 0,
            loading: false,
        },
        billsInProgress: {
            data: null,
            total: 0,
            loading: false,
        },
        billsCompleted: {
            data: null,
            total: 0,
            loading: false,
        },
    },
};

const getters = {
    billsData: () => state.billsData,
};

const actions = {
    /**
     *  Получение пользователя
     */
    getTableData({ commit }, params) {
        commit('changeLoading', params.type);

        return axios.get(`/api/${params.url}?page=${params.page}&limit=${params.limit}`)
            .then((resp) => {
                commit('getTableData', { response: resp.data, type: params.type });
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                commit('changeLoading', params.type);
            });
    },

    /**
     *  Получение акта
     */
    getActFile({ commit }, params) {
        return axios.get(`/api/acts/${params.row.number}/${params.row.version}/download`, { responseType: 'arraybuffer' })
            .then((resp) => {
                console.log(resp);
                commit('getFile', { ind: params.ind, type: params.type, data: resp.data });
                return Promise.resolve();
            })
            .catch((err) => {
                console.log(err);
            });
    },

    /**
     *  Получение счета
     */
    getBillFile({ commit }, params) {
        return axios.get(`/api/bills/${params.row.id}/download`, { responseType: 'arraybuffer' })
            .then((resp) => {
                console.log(resp);
                commit('getFile', { ind: params.ind, type: params.type, data: resp.data });
                return Promise.resolve();
            })
            .catch((err) => {
                console.log(err);
            });
    },

    /**
     * Подписать акт
     */
    signAct({ commit }, params) {
        return axios.post(`api/acts/${params.id}/sign-agent`)
            .then((resp) => Promise.resolve())
            .catch((err) => Promise.reject(err));
    },

    /**
     * Отклонить акт
     */
    rejectAct({ commit }, params) {
        return axios.post(`api/acts/${params.id}/reject-agent`, { comment: params.comment })
            .then((resp) => Promise.resolve())
            .catch((err) => {
                console.log(err);
            });
    },

    /**
     * Подписать счёт
     */
    signBill({ commit }, params) {
        return axios.post(`api/bills/${params.id}/sign-agent`)
            .then((resp) => Promise.resolve())
            .catch((err) => Promise.reject(err));
    },

    /**
     * Отклонить счёт
     */
    rejectBill({ commit }, params) {
        return axios.post(`api/bills/${params.id}/reject-agent`, { comment: params.comment })
            .then((resp) => Promise.resolve())
            .catch((err) => {
                console.log(err);
            });
    },

};

const mutations = {
    changeLoading(state, payload) {
        state.billsData[payload].loading = !state.billsData[payload].loading;
    },
    getTableData(state, payload) {
        state.billsData[payload.type].data = payload.response.data;
        state.billsData[payload.type].data.forEach((item) => {
            item.fileUrl = null;
        });
        state.billsData[payload.type].total = payload.response.pagination.total;
    },

    getFile(state, payload) {
        state.billsData[payload.type].data[payload.ind].fileUrl = URL.createObjectURL(new Blob([payload.data], { type: 'application/pdf' }));
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
