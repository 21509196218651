<template>
    <div>
        <acts-table :component="component" />
    </div>
</template>

<script>
import ActsTable from '@/views/accounts/parts/acts-table.vue';

export default {
    name: 'ActsCompleted',
    components: { ActsTable },
    props: ['component'],
};
</script>

<style scoped>

</style>
