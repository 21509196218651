import { render, staticRenderFns } from "./leads-period-select.vue?vue&type=template&id=125235d4&scoped=true"
import script from "./leads-period-select.vue?vue&type=script&lang=js"
export * from "./leads-period-select.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "125235d4",
  null
  
)

export default component.exports