<template>
    <div>
        <h1 class="text_dark mb_16">
            Материалы
        </h1>

        <p class="text_15 text_gray mb_16">
            Здесь мы храним рекламные и информационные материалы
        </p>
        <div v-if="userData.user.agentType !== 'corp'">

            <mp-tabs :tabs="tabs" class="mb_24" @changeTab="changeTab"/>

            <div v-if="!userData.loading" class="docs__list">
                <docs-list-item v-for="(item, ind) in activeList" :key="ind" :item="item"/>
            </div>

            <el-skeleton
                v-else
                :count="1"
                :rows="3"
                animated
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MpTabs from '@/components/UI/MpTabs.vue';
import DocsListItem from '@/views/docs/docs-list-item.vue';

export default {
    name: 'DocsView',
    components: { DocsListItem, MpTabs },
    data() {
        return {
            active: 'referrals',
            tabs: [
                {
                    id: 'referrals',
                    name: 'Рефeральная ссылка',
                },
                {
                    id: 'offers',
                    name: 'Коммерческие предложения',
                },
                {
                    id: 'images',
                    name: 'Логотипы и баннеры',
                },
                {
                    id: 'docs',
                    name: 'Официальные документы',
                },
                {
                    id: 'memo',
                    name: 'Памятка про ЛК',
                },
            ],
            items: {
                referrals: [
                    {
                        name: 'Реферальная ссылка',
                        url: 'https://www.rosbank.ru/malomu-biznesu/zayavka-pro/',
                        icon: 'link',
                    },
                ],
                offers: [
                    {
                        name: 'Акция по эквайрингу',
                        url: 'https://api.rosbank.ru/doc/ekvairing-kommercheskoe-predlozhenie.pdf',
                        icon: 'doc',
                    },
                    {
                        name: 'Презентация для клиентов',
                        url: 'https://api.rosbank.ru/doc/rko-bazovoe-dlya-malogo-biznesa-ot-rosbanka.pdf',
                        icon: 'doc',
                    },
                    {
                        name: 'Краткая версия презентации для клиентов',
                        url: 'https://api.rosbank.ru/doc/kp-agentam-mini-rko-ekvairing.pdf',
                        icon: 'doc',
                    },
                    {
                        name: 'Общие тарифы и условия ПАО Росбанк для предпринимателей и малого бизнеса',
                        url: 'https://www.rosbank.ru/malomu-biznesu/tarify',
                        icon: 'doc',
                    },
                ],
                images: [
                    {
                        name: 'Логотипы',
                        url: 'https://api.rosbank.ru/doc/logotip-rosbanka-i-rukovodstvo-po-firmennomu-stilyu.zip',
                        icon: 'image',
                    },
                    {
                        name: 'Баннеры',
                        url: 'https://api.rosbank.ru/doc/raschetnyi-schet-dlya-biznesa-bannery.zip',
                        icon: 'image',
                    },
                ],
                docs: [
                    {
                        name: 'Агентская программа Росбанка',
                        url: 'https://www.rosbank.ru/l/aaCrCy',
                        icon: 'portfel',
                    },
                ],
                memo: [
                    {
                        name: 'Памятка о работе с ЛК, подписание актов и счетов через ЭДО',
                        url: 'https://api.rosbank.ru/doc/elektronnoe-podpisanie-pamyatka.pdf',
                        icon: 'doc',
                    },
                ],
            },
        };
    },
    computed: {
        ...mapGetters('User', ['userData']),
        activeList() {
            return this.items[this.active];
        },
    },
    watch: {
        'userData.user': function () {
            this.setMgmCode();
        },
    },
    created() {
        if (this.userData.user) {
            this.setMgmCode();
        }
    },
    methods: {
        setMgmCode() {
            this.items.referrals = this.items.referrals.map((item) => {
                item.url = `${item.url}${this.userData.user.mgmCode}`;
                return item;
            });
        },
        changeTab(e) {
            this.active = e;
        },
    },
};
</script>

<style scoped lang="scss">
</style>
