<template>
    <div>
        <a class="text_15 text_gray text_b mr_16 text_underline-none cp" @click="open">
            Тариф
        </a>

        <el-drawer
            :append-to-body="true"
            :visible.sync="drawerVisible"
            :with-header="false"
            :wrapper-closable="true"
            class="mp__drawer el-drawer__750"
            direction="rtl"
        >
            <div class="el-drawer__scroll-block">
                <div class="el-drawer__custom-header">
                    <img
                        alt="close"
                        class="el-drawer__close cp"
                        src="/img/icons/close-big.svg"
                        @click="drawerVisible = false"
                    >
                </div>

                <h2 class="mb_16">
                    {{ userData?.tariff?.name }}
                </h2>

                <p class="text_15 text_gray mb_24">
                    {{ userData?.tariff.period }}
                </p>

                <el-table
                    ref="table"
                    :data="userData?.tariff?.jsonData?.MSK"
                    class="mp__table mp__table-header-dark"
                    style="width: 100%"
                >
                    <el-table-column
                        label="Клиентов в мес."
                    >
                        <template slot-scope="scope">
                            <p class="text_12 text_dark">
                                {{ `${scope.row.privlecheno_ot} - ${scope.row.privlecheno_do}` }}
                            </p>
                        </template>
                    </el-table-column>

                    <el-table-column
                        label="Срок регистрации в ФНС"
                    >
                        <template slot-scope="scope">
                            <p class="text_12 text_dark">
                                {{ `${scope.row.client_reg_time_days}+` }}
                            </p>
                        </template>
                    </el-table-column>

                    <el-table-column
                        label="Вознаграждение"
                    >
                        <template slot-scope="scope">
                            <p class="text_12 text_dark">
                                {{ scope.row.nagrada }}
                            </p>
                        </template>
                    </el-table-column>

                    <el-table-column
                        label="Доп. выплата"
                    >
                        <template slot-scope="scope">
                            <p class="text_12 text_dark">
                                {{ scope.row.doplata }}
                            </p>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'TariffsDrawer',
    data() {
        return {
            drawerVisible: false,
        };
    },
    computed: {
        ...mapGetters('User', ['userData']),
    },
    methods: {
        open() {
            this.drawerVisible = true;
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
