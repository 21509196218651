<template>
    <div>
        <a class="text_15 text_gray text_b mr_16 text_underline-none cp" @click="changeVisible">
            Обратная связь
        </a>

        <el-drawer
            :append-to-body="true"
            :visible.sync="drawerVisible"
            :with-header="false"
            :wrapper-closable="false"
            class="mp__drawer el-drawer__750"
            direction="rtl"
            @open="init"
        >
            <div class="el-drawer__scroll-block">
                <div class="el-drawer__custom-header">
                    <img
                        alt="close"
                        class="el-drawer__close cp"
                        src="/img/icons/close-big.svg"
                        @click="changeVisible"
                    >
                </div>

                <h2 class="mb_16">
                    Обратная связь
                </h2>

                <div v-if="feedback">
                    <div class="relative mb_24">
                        <el-select
                            v-model="feedback.id"
                            placeholder="Выберите категорию"
                            class="el-input__large w-100"
                            :class="{'common__input-error': $v.feedback.id.$error}"
                            popper-class="el-select-opinion-large"
                        >
                            <el-option
                                v-for="item in categories"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            />
                        </el-select>

                        <p v-if="feedback.id" class="text_12 text_gray input-large__label">
                            Категория
                        </p>

                        <p v-if="$v.feedback.id.$error" class="text_12 text_red common__error-text">
                            Заполните поле
                        </p>
                    </div>

                    <div class="relative mb_32">
                        <el-input
                            v-model="feedback.text"
                            :class="{'common__textarea-error': $v.feedback.text.$error}"
                            :show-word-limit="true"
                            class="el-input__large el-custom_textarea"
                            maxlength="1000"
                            placeholder="Введите текст"
                            rows="3"
                            type="textarea"
                        />

                        <p v-if="$v.feedback.text.$error" class="text_12 text_red common__error-text">
                            Заполните поле
                        </p>
                    </div>

                    <div class="flex flex__jc-fe">
                        <el-button class="btn_48 btn_white" @click="changeVisible">
                            Отмена
                        </el-button>

                        <el-button :loading="loading" class="btn_48 btn_red" @click="send">
                            Отправить
                        </el-button>
                    </div>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators/index.js';

function Feedback() {
    this.id = null;
    this.text = null;
}

export default {
    name: 'FeedbackDrawer',
    data() {
        return {
            feedback: null,
            categories: [
                {
                    id: 1,
                    name: 'Отзыв',
                },
                {
                    id: 2,
                    name: 'Предложение',
                },
                {
                    id: 3,
                    name: 'Ошибка',
                },
            ],
            loading: false,
            drawerVisible: false,
        };
    },
    validations: {
        feedback: {
            id: {
                required,
            },
            text: {
                required,
            },
        },
    },
    computed: {
        ...mapGetters('User', ['userData']),
    },
    methods: {
        ...mapActions('User', ['sendFeedback']),
        init() {
            if (!this.feedback) {
                this.feedback = new Feedback();
            }
        },
        changeVisible() {
            this.drawerVisible = !this.drawerVisible;
        },
        send() {
            this.$v.$touch();

            if (!this.$v.$invalid) {
                this.loading = true;

                this.sendFeedback(this.feedback)
                    .then(() => {
                        this.$v.$reset();
                        this.feedback = new Feedback();
                        this.$notify.success({ title: 'Успешно', message: 'Обращение успешно отправлено' });
                        this.drawerVisible = false;
                    })
                    .catch(() => {
                        this.$notify.error({ title: 'Ошибка', message: 'Что-то пошло не так' });
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
