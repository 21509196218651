<template>
    <div>
        <div class="flex flex__jc-sb mb_32">
            <div>
                <h1 class="text_dark mb_16">
                    Заявки
                </h1>

                <leads-period-select @needUpdate="updateCurrent" />
            </div>

            <leads-add-drawer />
        </div>

        <leads-summary />

        <mp-tabs :tabs="tabs" class="mb_32" @changeTab="changeTab" />

        <keep-alive>
            <component :is="component" :component="component" />
        </keep-alive>
    </div>
</template>

<script>
import LeadsPeriodSelect from '@/views/leads/parts/leads-period-select.vue';
import LeadsSummary from '@/views/leads/parts/leads-summary.vue';
import LeadsCreated from '@/views/leads/LeadsCreated.vue';
import LeadsInProgress from '@/views/leads/LeadsInProgress.vue';
import LeadsCompleted from '@/views/leads/LeadsCompleted.vue';
import LeadsRejected from '@/views/leads/LeadsRejected.vue';
import MpTabs from '@/components/UI/MpTabs.vue';
import LeadsAddDrawer from '@/views/leads/parts/leads-add-drawer.vue';

export default {
    name: 'LeadsView',
    components: {
        LeadsAddDrawer,
        MpTabs,
        LeadsRejected,
        LeadsCompleted,
        LeadsInProgress,
        LeadsCreated,
        LeadsSummary,
        LeadsPeriodSelect,
    },
    data() {
        return {
            component: 'leadsCreated',
            tabs: [
                {
                    id: 'leadsCreated',
                    name: 'Все',
                },
                {
                    id: 'leadsInProgress',
                    name: 'На рассмотрении',
                },
                {
                    id: 'leadsCompleted',
                    name: 'Привлечено',
                },
                {
                    id: 'leadsRejected',
                    name: 'Отказано',
                },
            ],
        };
    },
    methods: {
        updateCurrent() {

        },
        changeTab(e) {
            this.component = e;
        },
    },
};
</script>

<style scoped>

</style>
