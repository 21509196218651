<template>
    <div>
        <div class="flex flex__jc-fe mb_32">
            <el-button v-if="requisites" class="btn_white btn_40" @click="setViewMode">
                Отменить
            </el-button>

            <el-button :loading="requisitesData.saveLoading" class="btn_red btn_40" @click="save">
                Сохранить
            </el-button>
        </div>

        <requisites-form ref="form" :requisites="requisites" @saveSuccess="updateList" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RequisitesForm from '@/views/requisites/requisites-form.vue';

export default {
    name: 'RequisitesEdit',
    components: { RequisitesForm },
    computed: {
        ...mapGetters('Requisites', ['requisitesData']),
        requisites() {
            return this.requisitesData.list?.[0];
        },
    },
    methods: {
        setViewMode() {
            this.$emit('setViewMode');
        },
        updateList() {
            this.$emit('updateList');
        },
        save() {
            this.$refs.form.save();
        },
    },
};
</script>

<style scoped>

</style>
