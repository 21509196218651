<template>
    <div>
        <h1 class="mb_32 text_dark">
            Счета и акты
        </h1>

        <mp-tabs :tabs="tabs" class="mb_32" @changeTab="changeTab" />

        <keep-alive>
            <component :is="component" :component="component" />
        </keep-alive>
    </div>
</template>

<script>
import MpTabs from '@/components/UI/MpTabs.vue';
import BillsCompleted from '@/views/accounts/BillsCompleted.vue';
import BillsInProgress from '@/views/accounts/BillsInProgress.vue';
import ActsCompleted from '@/views/accounts/ActsCompleted.vue';
import ActsInProgress from '@/views/accounts/ActsInProgress.vue';

export default {
    name: 'AccountsView',
    components: {
        ActsInProgress, ActsCompleted, BillsInProgress, BillsCompleted, MpTabs,
    },
    data() {
        return {
            component: 'actsInProgress',
            tabs: [
                {
                    id: 'actsInProgress',
                    name: 'Акты в работе',
                },
                {
                    id: 'actsCompleted',
                    name: 'Завершенные акты',
                },
                {
                    id: 'billsInProgress',
                    name: 'Счета в работе',
                },
                {
                    id: 'billsCompleted',
                    name: 'Завершенные счета',
                },
            ],
        };
    },
    methods: {
        changeTab(e) {
            this.component = e;
        },
    },
};
</script>

<style scoped>

</style>
