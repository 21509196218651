<template>
    <div>
        <el-button class="btn_48 btn_red" @click="changeVisible">
            Добавить
        </el-button>

        <el-drawer
            :append-to-body="true"
            :visible.sync="drawerVisible"
            :with-header="false"
            :wrapper-closable="false"
            class="mp__drawer el-drawer__750"
            direction="rtl"
            @open="init"
        >
            <div class="el-drawer__scroll-block">
                <div class="el-drawer__custom-header">
                    <img
                        alt="close"
                        class="el-drawer__close cp"
                        src="/img/icons/close-big.svg"
                        @click="changeVisible"
                    >
                </div>

                <h2 class="mb_32">
                    Отправить заявку на РКО
                </h2>
                <div v-if="!isCorp"
                     style="
display: flex;
flex-direction: row;
align-items: flex-start;
padding: 0px;
padding-top: 12px;
gap: 16px;

width: 698px;
height: 100px;

/* Inside auto layout */
flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;

background: #EB5757;
border-radius: 8px;
">
                    <div  style="
display: flex;
flex-direction: row;
/* Inside auto layout */
flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
padding-top: 30px;
padding-left: 10px
">
                        <img src="../../../assets/notice_logo.png"
                             style="/* icon_info_warning_white */

width: 20px;
height: 20px;

/* Inside auto layout */
flex: none;
order: 0;
flex-grow: 0;
"/>
                    </div>
                    <div style="
/* Container */

/* Auto layout */
display: flex;
flex-direction: row;
align-items: flex-start;
padding: 0px;
gap: 16px;
color: white;

/* Inside auto layout */
flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;">
                        С <b>1 июля 2024г.</b> РОСБАНК прекращает прием агентских заявок на РКО и иные
                        продукты и сервисы. Соответствующие уведомления были отправлены <b>19, 21</b> и <b>24</b> июня на
                        электронный адрес (<b>{{userData.user.email}}</b>), указанный при регистрации в агентской программе.
                        Подробности у вашего куратора и в <b><a download href="https://api.rosbank.ru/doc/pro-uvedomlenie-agentam-o-stop-priema-s-1-iyulya.pdf">файле</a></b>
                    </div>
                </div>
                <br>
                <div v-if="lead && (isCorp || !proLeadStop)">

                    <!--         Заявка на РКО/эквайринг           -->
                    <div v-if="!lead.reg_bus">
                        <div class="flex mb_24">
                            <!--                        ФИО контактного лица-->
                            <div class="relative w-100 mr_16">
                                <el-input
                                    v-model="lead.lead_contact_name"
                                    :class="{'common__input-error': $v.lead.lead_contact_name.$error}"
                                    class="el-input__large"
                                    placeholder="ФИО контактного лица"
                                />

                                <p v-if="lead.lead_contact_name" class="text_12 text_gray input-large__label">
                                    ФИО контактного лица
                                </p>

                                <p v-if="$v.lead.lead_contact_name.$error" class="text_12 text_red common__error-text">
                                    Заполните поле
                                </p>
                            </div>

                            <!--                        Телефон контактного лица-->
                            <div class="relative w-100">
                                <mp-phone-masked-input
                                    v-model="lead.lead_phone"
                                    :class="{'common__input-error': $v.lead.lead_phone.$error}"
                                    :mask="'+7 ### ###-##-##'"
                                    class="el-input__large"
                                    placeholder="Телефон контактного лица"
                                />

                                <p v-if="lead.lead_phone" class="text_12 text_gray input-large__label">
                                    Телефон контактного лица
                                </p>

                                <p v-if="$v.lead.lead_phone.$error" class="text_12 text_red common__error-text">
                                    Заполните поле
                                </p>
                            </div>
                        </div>

                        <!--                    чекбоксы-->
                        <div class="mb_24" v v-if="!isCorp">
                            <div class="mb_16">
                                <el-checkbox v-model="lead.check_inn" class="el-checkbox__red">
                                    Есть ИНН
                                </el-checkbox>
                            </div>
                        </div>

                        <!--                        ИНН-->
                        <div v-if="lead.check_inn" class="relative w-100 mb_24">
                            <el-input
                                v-model="lead.registered_inn"
                                :class="{'common__input-error': $v.lead.registered_inn.$error}"
                                class="el-input__large"
                                placeholder="ИНН"
                                :maxlength="12"
                            />

                            <p v-if="lead.registered_inn" class="text_12 text_gray input-large__label">
                                ИНН
                            </p>

                            <p v-if="$v.lead.registered_inn.$error" class="text_12 text_red common__error-text">
                                ИНН должен иметь 10 или 12 символов и состоять только из цифр
                            </p>
                        </div>

                        <div v-else class="mb_24">
                            <!--                        ФИО-->
                            <div class="relative w-100 mb_24">
                                <el-input
                                    v-model="lead.fio"
                                    :class="{'common__input-error': $v.lead.fio.$error}"
                                    class="el-input__large"
                                    placeholder="ФИО директора компании"
                                />

                                <p v-if="lead.fio" class="text_12 text_gray input-large__label">
                                    ФИО директора компании
                                </p>

                                <p v-if="$v.lead.fio.$error" class="text_12 text_red common__error-text">
                                    Заполните поле
                                </p>
                            </div>

                            <!--                        Дата рождения-->
                            <div class="relative w-100">
                                <div class="el-input__large" :class="{'common__input-error': $v.lead.birthday_date.$error}">
                                    <the-mask
                                        v-model="lead.birthday_date"
                                        :mask="'##.##.####'"
                                        :masked="true"
                                        placeholder="Дата рождения директора"
                                        class="el-input__inner"
                                    />
                                </div>

                                <p v-if="lead.birthday_date" class="text_12 text_gray input-large__label">
                                    Дата рождения директора
                                </p>

                                <p v-if="$v.lead.birthday_date.$error" class="text_12 text_red common__error-text">
                                    Заполните поле
                                </p>
                            </div>
                        </div>

                        <!--                        Коммент-->
                        <el-input
                            v-model="lead.comment"
                            :show-word-limit="true"
                            class="el-input__large el-custom_textarea mb_24"
                            maxlength="1000"
                            placeholder="Введите ваш комментарий"
                            rows="3"
                            type="textarea"
                        />
                    </div>

                    <!--         Заявка на регбиз           -->
                    <div v-else>
                        <!-- Регистрация бизнеса -->
                        <div class=" mb_24">
                            <p
                                class="text_12 mb_12">
                                Выберите правовую форму
                            </p>
                            <el-radio-group
                                v-model="lead.legal_form"
                                v-if="lead.reg_bus"
                                :class="{'common__input-error': $v.lead.legal_form.$error}"
                            >
                                <div class="relative w-100 mb_12">
                                    <el-radio :label="1">Индивидуальный предприниматель</el-radio>
                                </div>
                                <div class="relative w-100 nb_12">
                                    <el-radio :label="2">Общество с ограниченной ответственностью</el-radio>
                                </div>
                            </el-radio-group>
                            <div class="relative w-100">
                                <p v-if="$v.lead.legal_form.$error"
                                   class="text_12 text_red common__error-text">
                                    Выберите правовую форму
                                </p>
                            </div>
                        </div>
                        <div v-if="lead.legal_form !== 0">
                            <div class="flex mb_24">
                                <!--                        ФИО контактного лица-->
                                <div class="relative w-100 mr_16">
                                    <el-input
                                        v-model="lead.lead_contact_name"
                                        :class="{'common__input-error': $v.lead.lead_contact_name.$error}"
                                        class="el-input__large"
                                        placeholder="ФИО контактного лица"
                                    />

                                    <p v-if="lead.lead_contact_name" class="text_12 text_gray input-large__label">
                                        ФИО контактного лица
                                    </p>

                                    <p v-if="$v.lead.lead_contact_name.$error" class="text_12 text_red common__error-text">
                                        Заполните поле
                                    </p>
                                </div>

                                <!--                        Телефон контактного лица-->
                                <div class="relative w-100">
                                    <mp-phone-masked-input
                                        v-model="lead.lead_phone"
                                        :class="{'common__input-error': $v.lead.lead_phone.$error}"
                                        :mask="'+7 ### ###-##-##'"
                                        class="el-input__large"
                                        placeholder="Телефон контактного лица"
                                    />

                                    <p v-if="lead.lead_phone" class="text_12 text_gray input-large__label">
                                        Телефон контактного лица
                                    </p>

                                    <p v-if="$v.lead.lead_phone.$error" class="text_12 text_red common__error-text">
                                        Заполните поле
                                    </p>
                                </div>
                            </div>
                            <div class="flex mb_24">
                                <!--                        ИНН-->
                                <div  class="relative w-100 mr_16" v-if="lead.legal_form === 1">
                                    <el-input
                                        v-model="lead.registered_inn"
                                        :class="{'common__input-error': $v.lead.registered_inn.$error}"
                                        class="el-input__large"
                                        placeholder="ИНН"
                                        :maxlength="12"
                                    />

                                    <p v-if="lead.registered_inn" class="text_12 text_gray input-large__label">
                                        ИНН
                                    </p>

                                    <p v-if="$v.lead.registered_inn.$error" class="text_12 text_red common__error-text">
                                        ИНН должен иметь 10 или 12 символов и состоять только из цифр
                                    </p>
                                </div>
                                <!--                        Название организации-->
                                <div class="relative w-100 mr_16" v-if="lead.legal_form === 2">
                                    <el-input
                                        v-model="lead.org_name"
                                        :class="{'common__input-error': $v.lead.org_name.$error}"
                                        class="el-input__large"
                                        placeholder="Название организации"
                                    />

                                    <p v-if="lead.org_name" class="text_12 text_gray input-large__label">
                                        Название организации
                                    </p>

                                    <p v-if="$v.lead.org_name.$error" class="text_12 text_red common__error-text">
                                        Заполните поле
                                    </p>
                                </div>
                                <!--                        Email-->
                                <div  class="relative w-100 mb_16">
                                    <el-input
                                        v-model="lead.email"
                                        class="el-input__large"
                                        placeholder="Email"
                                        :class="{'common__input-error': $v.lead.email.$error}"
                                    />

                                    <p v-if="lead.email" class="text_12 text_gray input-large__label">
                                        E-mail
                                    </p>
                                    <p v-if="$v.lead.email.$error" class="text_12 text_red common__error-text">
                                        Неверный формат
                                    </p>
                                </div>
                            </div>

                            <!--                        Коммент-->
                            <el-input
                                v-model="lead.comment"
                                :show-word-limit="true"
                                class="el-input__large el-custom_textarea mb_24"
                                maxlength="1000"
                                placeholder="Введите ваш комментарий"
                                rows="3"
                                type="textarea"
                            />
                        </div>
                    </div>

                    <!--                        принятие условий-->
                    <div class="relative mb_32">
                        <div>
                            <el-checkbox v-model="lead.check_approval" class="el-checkbox__red" />

                            <a class="text_12 text_dark ml_8 cp" @click="changeDialogVisible">
                                Согласие на обработку персональных данных
                            </a>
                        </div>

                        <p v-if="$v.lead.check_approval.$error" class="text_12 text_red common__error-text">
                            Необходимо согласие
                        </p>
                    </div>

                    <div class="flex flex__jc-fe">
                        <el-button class="btn_48 btn_white" @click="changeVisible">
                            Отмена
                        </el-button>

                        <el-button :loading="loading" class="btn_48 btn_red" @click="send">
                            Отправить
                        </el-button>
                    </div>
                </div>
            </div>
        </el-drawer>

        <el-dialog
            :append-to-body="true"
            :show-close="false"
            :visible.sync="dialogVisible"
            custom-class="mp_dialog mp_dialog-small"
        >
            <div slot="title" class="el-dialog__header-inner">
                <h2>Согласие на обработку персональных данных</h2>

                <img
                    alt="close"
                    class="mp__dialog-close cp"
                    src="/img/icons/dialog-close.svg"
                    @click="changeDialogVisible"
                >
            </div>

            <div class="el-dialog__body-inner">
                <p class="text_12 text_dark">
                    Настоящим я, свободно, своей волей и в своем интересе, предоставляю ПАО РОСБАНК, 107078, г. Москва, ул. Маши Порываевой, д. 34 (далее — Банк) согласие на автоматизированную и неавтоматизированную обработку моих персональных данных, предоставленных мной путем заполнения веб-формы на сайте www.rosbank.ru, а также иных имеющихся у Банка сведений, с целью заключения договора с Банком, получения обратной связи по продуктам Банка. Обработка моих персональных данных включает в себя: сбор, систематизацию, накопление, хранение, уточнение (обновление, изменение), использование, передачу (предоставление, доступ) третьим лицам*,их работникам и иным уполномоченным ими лицам, блокирование и уничтожение. Обработка моих персональных данных ограничивается сроком действия согласия. Согласие действует с момента его предоставления до его отзыва в соответствующей форме.
                    <br><br>
                    *Под третьими лицами понимаются лица, заключившие с Банком договор, обеспечивающий соблюдение требований Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных».
                </p>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { TheMask } from 'vue-the-mask';
import {
    email, minLength, required, requiredUnless,
} from 'vuelidate/lib/validators/index.js';
import MpPhoneMaskedInput from '@/components/UI/mpPhoneMaskedInput.vue';

function Lead() {
    this.lead_contact_name = null;
    this.lead_phone = null;
    this.check_inn = false;
    this.acquire_lead = 0;
    this.reg_bus = 0; // Флаг передачи регбиза
    this.comment = null;
    this.registered_inn = '';
    this.fio = null;
    this.birthday_date = '';
    this.check_approval = false;
    this.legal_form = 0; // Выбор правовой формы для регбиза
    this.email = ''; // email для регбиза
    this.org_name = ''; // Имя организации для регбиза
}

const checkInn = (value, vm) => {
    let result = true;

    // Если включен чекбокс "Есть ИНН" или выбран РегБиз для правовой формы ИП
    if (vm.check_inn || (vm.reg_bus && vm.legal_form === 1)) {
        result = (value.length === 10 || value.length === 12) && /^\d+$/.test(value);
    }

    return result;
};

const checkBirthdayDate = (value, vm) => {
    let result = true;

    // Дата рождения для регбиза не нужна
    if (vm.reg_bus) {
        return true;
    }

    if (!vm.check_inn) {
        result = value.length === 10;
    }

    return result;
};

const checkLegalForm = (value, vm) => {
    if (!vm.reg_bus) {
        return true;
    }

    return vm.legal_form !== 0;
};

const checkApproval = (value) => value;

export default {
    name: 'LeadsAddDrawer',
    components: { MpPhoneMaskedInput, TheMask },
    data() {
        return {
            lead: null,
            loading: false,
            drawerVisible: false,
            dialogVisible: false,
            isCorp: false,
            proLeadStop: false,
        };
    },
    computed: {
        ...mapGetters('User', ['userData']),
    },
    validations: {
        lead: {
            lead_contact_name: {
                required,
            },
            lead_phone: {
                required,
                minLength: minLength(10),
            },
            registered_inn: {
                checkInn,
            },
            fio: { // ФИО не нужно только для РКО/эквайринг без ИНН
                required: requiredUnless(function () {
                    return this.lead.check_inn || this.lead.reg_bus;
                }),
            },
            birthday_date: {
                checkBirthdayDate,
            },
            check_approval: {
                checkApproval,
            },
            legal_form: {
                checkLegalForm,
            },
            email: {
                email,
                // email нужен только для регбиза
                required: requiredUnless(function () {
                    return !this.lead.reg_bus;
                }),
            },
            org_name: {
                // Название организации нужно только для регбиза с правовой формой 2 (ООО)
                required: requiredUnless(function () {
                    return !this.lead.reg_bus || this.lead.legal_form !== 2;
                }),
            },
        },
    },
    methods: {
        ...mapActions('Leads', ['createLead']),
        init() {
            this.isCorp = this.userData.user.agentType === 'corp';
            if (!this.lead) {
                this.lead = new Lead();
                if (this.isCorp) {
                    this.lead.check_inn = true;
                }
            }
            const dateProStop = new Date('2024-06-26');
            if (new Date() > dateProStop) {
                this.proLeadStop = true;
            }
        },
        changeVisible() {
            this.drawerVisible = !this.drawerVisible;
        },
        changeDialogVisible() {
            this.dialogVisible = !this.dialogVisible;
        },
        send() {
            this.$v.$touch();

            if (!this.$v.$invalid) {
                this.loading = true;

                const lead = JSON.parse(JSON.stringify(this.lead));

                if (lead.lead_phone[0] !== '7') {
                    lead.lead_phone = `7${lead.lead_phone}`;
                }

                lead.acquire_lead = lead.acquire_lead ? 1 : 0;
                lead.reg_bus = lead.reg_bus ? 1 : 0;
                // Костыль для обхода проверки ИНН. Если указана дата рождения, не проверяет ИНН
                if (lead.reg_bus === 1) {
                    lead.birthday_date = '01.01.1970';
                }

                this.createLead(lead)
                    .then(() => {
                        this.lead = new Lead();
                        this.$v.$reset();
                        this.drawerVisible = false;

                        this.$notify.success({ title: 'Успешно', message: 'Заявка успешно создана' });
                    })
                    .catch(() => {
                        this.$notify.error({ title: 'Ошибка', message: 'Что-то пошло не так' });
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
