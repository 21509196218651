<template>
    <div v-loading="requisitesData.saveLoading">
        <div class="flex mb_24">
            <!--                        КПП-->
            <div class="relative w-100 mr_16">
                <el-input
                    v-model="requisitesEdit.kpp"
                    :class="{'common__input-error': $v.requisitesEdit.kpp.$error}"
                    class="el-input__large"
                    placeholder="КПП"
                    :maxlength="9"
                    clearable
                    :disabled="disabled"
                />

                <p v-if="requisitesEdit.kpp" class="text_12 text_gray input-large__label">
                    КПП
                </p>

                <p v-if="$v.requisitesEdit.kpp.$error" class="text_12 text_red common__error-text">
                    Заполните поле. КПП состоит из 9 цифр
                </p>
            </div>

            <!--                        БИК-->
            <div class="relative w-100">
                <el-input
                    v-model="requisitesEdit.bik"
                    :class="{'common__input-error': $v.requisitesEdit.bik.$error}"
                    class="el-input__large"
                    placeholder="БИК"
                    :maxlength="9"
                    clearable
                    :disabled="disabled"
                />

                <p v-if="requisitesEdit.bik" class="text_12 text_gray input-large__label">
                    БИК
                </p>

                <p v-if="$v.requisitesEdit.bik.$error" class="text_12 text_red common__error-text">
                    Заполните поле. Бик состоит из 9 цифр
                </p>
            </div>
        </div>

        <div class="flex mb_24">
            <!--                        Наименование банка получателя-->
            <div class="relative w-100 mr_16">
                <el-input
                    v-model="requisitesEdit.bank_name"
                    :class="{'common__input-error': $v.requisitesEdit.bank_name.$error}"
                    class="el-input__large"
                    placeholder="Наименование банка получателя"
                    :maxlength="300"
                    clearable
                    :disabled="disabled"
                />

                <p v-if="requisitesEdit.bank_name" class="text_12 text_gray input-large__label">
                    Наименование банка получателя
                </p>

                <p v-if="$v.requisitesEdit.bank_name.$error" class="text_12 text_red common__error-text">
                    Заполните поле
                </p>
            </div>

            <!--                        Корр.счёт банка-->
            <div class="relative w-100">
                <el-input
                    v-model="requisitesEdit.correspondent_account"
                    :class="{'common__input-error': $v.requisitesEdit.correspondent_account.$error}"
                    class="el-input__large"
                    placeholder="Корр.счёт банка"
                    :maxlength="20"
                    clearable
                    :disabled="disabled"
                />

                <p v-if="requisitesEdit.correspondent_account" class="text_12 text_gray input-large__label">
                    Корр.счёт банка
                </p>

                <p v-if="$v.requisitesEdit.correspondent_account.$error" class="text_12 text_red common__error-text">
                    Заполните поле. Счет должен содержать 20 цифр
                </p>
            </div>
        </div>

        <!--                        Наименование получателя-->
        <div class="relative w-100 mb_24">
            <el-input
                v-model="requisitesEdit.recipient_name"
                :class="{'common__input-error': $v.requisitesEdit.recipient_name.$error}"
                class="el-input__large"
                placeholder="Наименование получателя"
                :maxlength="160"
                clearable
                :disabled="disabled"
            />

            <p v-if="requisitesEdit.recipient_name" class="text_12 text_gray input-large__label">
                Наименование получателя
            </p>

            <p v-if="$v.requisitesEdit.recipient_name.$error" class="text_12 text_red common__error-text">
                Заполните поле
            </p>
        </div>

        <!--                        Счет получателя-->
        <div class="relative w-100 mb_24">
            <el-input
                v-model="requisitesEdit.recipient_account"
                :class="{'common__input-error': $v.requisitesEdit.recipient_account.$error}"
                class="el-input__large"
                placeholder="Счет получателя"
                :maxlength="20"
                clearable
                :disabled="disabled"
            />

            <p v-if="requisitesEdit.recipient_account" class="text_12 text_gray input-large__label">
                Счет получателя
            </p>

            <p v-if="$v.requisitesEdit.recipient_account.$error" class="text_12 text_red common__error-text">
                Заполните поле. Счет должен содержать 20 цифр
            </p>
        </div>

        <!--                        Адрес агента-->
        <div class="relative w-100 mb_24">
            <el-input
                v-model="requisitesEdit.agent_address"
                :class="{'common__input-error': $v.requisitesEdit.agent_address.$error}"
                class="el-input__large"
                placeholder="Адрес агента"
                :maxlength="300"
                clearable
                :disabled="disabled"
            />

            <p v-if="requisitesEdit.agent_address" class="text_12 text_gray input-large__label">
                Адрес агента
            </p>

            <p v-if="$v.requisitesEdit.agent_address.$error" class="text_12 text_red common__error-text">
                Заполните поле
            </p>
        </div>

        <div class="flex">
            <!--                        Телефон агента-->
            <div class="relative w-100 mr_16">
                <mp-phone-masked-input
                    v-model="requisitesEdit.agent_phone_number"
                    :class="{'common__input-error': $v.requisitesEdit.agent_phone_number.$error, 'is-disabled': disabled}"
                    :mask="'+7 ### ###-##-##'"
                    class="el-input__large"
                    placeholder="Телефон агента"
                    :disabled="disabled"
                />

                <p v-if="requisitesEdit.agent_phone_number" class="text_12 text_gray input-large__label">
                    Телефон агента
                </p>

                <p v-if="$v.requisitesEdit.agent_phone_number.$error" class="text_12 text_red common__error-text">
                    Заполните поле
                </p>
            </div>

            <!--                        ФИО бухгалтера-->
            <div class="relative w-100">
                <el-input
                    v-model="requisitesEdit.accountant_name"
                    class="el-input__large"
                    placeholder="ФИО бухгалтера"
                    :maxlength="160"
                    clearable
                    :disabled="disabled"
                />

                <p v-if="requisitesEdit.accountant_name" class="text_12 text_gray input-large__label">
                    ФИО бухгалтера
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import {
    minLength, required, numeric,
} from 'vuelidate/lib/validators/index.js';
import { mapActions, mapGetters } from 'vuex';
import MpPhoneMaskedInput from '@/components/UI/mpPhoneMaskedInput.vue';

function Requisites(params) {
    this.kpp = params?.kpp;
    this.bik = params?.bik;
    this.bank_name = params?.bank_name;
    this.correspondent_account = params?.correspondent_account;
    this.recipient_name = params?.recipient_name;
    this.recipient_account = params?.recipient_account;
    this.agent_address = params?.agent_address;
    this.agent_phone_number = params?.agent_phone_number;
    this.accountant_name = params?.accountant_name;
}

export default {
    name: 'RequisitesForm',
    components: { MpPhoneMaskedInput },
    props: ['requisites', 'disabled'],
    data() {
        return {
            requisitesEdit: null,
        };
    },
    validations: {
        requisitesEdit: {
            kpp: {
                minLength: minLength(9),
                numeric,
            },
            bik: {
                required,
                minLength: minLength(9),
                numeric,
            },
            bank_name: {
                required,
            },
            correspondent_account: {
                required,
                minLength: minLength(20),
                numeric,
            },
            recipient_name: {
                required,
            },
            recipient_account: {
                required,
                minLength: minLength(20),
                numeric,
            },
            agent_address: {
                required,
            },
            agent_phone_number: {
                required,
                minLength: minLength(10),
            },
        },
    },
    computed: {
        ...mapGetters('Requisites', ['requisitesData']),
    },
    created() {
        this.requisitesEdit = new Requisites(this.requisites);
    },
    methods: {
        ...mapActions('Requisites', ['createRequisites']),
        save() {
            this.$v.$touch();

            if (!this.$v.$invalid) {
                if (this.requisitesEdit.agent_phone_number[0] !== '7') {
                    this.requisitesEdit.agent_phone_number = `7${this.requisitesEdit.agent_phone_number}`;
                }

                this.createRequisites(this.requisitesEdit)
                    .then(() => {
                        this.$v.$reset();
                        this.$notify.success({ title: 'Успешно', message: 'Реквизиты успешно созданы' });
                        this.$emit('saveSuccess');
                    })
                    .catch(() => {
                        this.$notify.error({ title: 'Ошибка', message: 'Что-то пошло не так' });
                    });
            }
        },
    },
};
</script>

<style scoped>
</style>
