<template>
    <div>
        <div v-if="!billsData[component].loading || billsData[component].data" v-loading="billsData[component].loading">
            <el-table
                ref="table"
                :data="billsData[component].data"
                class="mp__table mp__table-header-dark"
                style="width: 100%"
                @expand-change="rowExpandChange"
            >
                <el-table-column type="expand">
                    <template slot-scope="scope">
                        <div v-loading="!billsData[component].data[scope.$index].fileUrl">
                            <div class="pdf-wrapper">
                                <vue-pdf-embed
                                    class="mb_32"
                                    :text-layer="true"
                                    :source="billsData[component].data[scope.$index].fileUrl ? billsData[component].data[scope.$index].fileUrl : ''"
                                />

                                <acts-controls
                                    v-if="showControls(billsData[component].data[scope.$index].statusId, billsData[component].data[scope.$index].fileUrl)"
                                    :act="billsData[component].data[scope.$index]"
                                    @needUpdate="getData"
                                />
                            </div>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column
                    label="Документ"
                >
                    <template slot-scope="scope">
                        <p class="text_12 text_dark">
                            {{ scope.row.actName }}
                        </p>
                    </template>
                </el-table-column>

                <el-table-column
                    label="Период"
                >
                    <template slot-scope="scope">
                        <p class="text_12 text_dark">
                            {{ scope.row.period }}
                        </p>
                    </template>
                </el-table-column>

                <el-table-column
                    label="Версия"
                >
                    <template slot-scope="scope">
                        <p class="text_12 text_dark">
                            {{ scope.row.version }}
                        </p>
                    </template>
                </el-table-column>

                <el-table-column
                    label="Актуальность"
                >
                    <template slot-scope="scope">
                        <p class="text_12 text_dark">
                            {{ scope.row.isActive }}
                        </p>
                    </template>
                </el-table-column>

                <el-table-column
                    label="Дата"
                >
                    <template slot-scope="scope">
                        <p class="text_12 text_dark">
                            {{ scope.row.createdDate }}
                        </p>
                    </template>
                </el-table-column>

                <el-table-column
                    label="Статус"
                >
                    <template slot-scope="scope">
                        <div class="flex flex__jc-c">
                            <p class="text_12 text_dark" style="padding-right: 3px;">
                                {{ scope.row.statusName }}
                            </p>
                            <img v-if="scope.row.bankComment !== null" :src="`/img/icons/critical.svg`" :title="scope.row.bankComment">
                        </div>
                    </template>
                </el-table-column>

                <el-table-column
                    label="Действия"
                    width="202px"
                >
                    <template slot-scope="scope">
                        <p class="text_12 text_dark">
                            <el-button :class="expandButtonColor(scope.row)" class="btn_dark btn_32" @click="expandRow(scope)">
                                {{ expandButtonTitle(scope.row) }}
                            </el-button>
                        </p>
                    </template>
                </el-table-column>

                <el-table-column align="right" width="50px">
                    <template slot-scope="scope">
                        <act-downloader :scope="scope" :component="component" />
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination
                :current-page.sync="pagination.page"
                :page-size.sync="pagination.limit"
                :page-sizes="[5, 10, 50, 100]"
                :total="billsData[component].total"
                class="mt_16"
                layout="total, sizes, slot, prev, next"
                style="text-align: right"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            >
                <template>
                    <span class="text_13 text_dark text_n">
                        {{ pagination.limit * (pagination.page - 1) + 1 }} -
                        {{
                            pagination.limit * pagination.page < billsData[component].total ? pagination.limit * pagination.page : billsData[component].total
                        }}
                        из {{ billsData[component].total }}
                    </span>
                </template>
            </el-pagination>
        </div>

        <el-skeleton
            v-if="billsData[component].loading && !billsData[component].data"
            :count="1"
            :rows="10"
            animated
        />
    </div>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';
import { mapActions, mapGetters } from 'vuex';
import ActsControls from '@/views/accounts/parts/acts-controls.vue';
import ActDownloader from '@/views/accounts/parts/act-downloader.vue';

export default {
    name: 'ActsTable',
    components: { ActDownloader, ActsControls, VuePdfEmbed },
    props: ['component'],
    data() {
        return {
            pagination: {
                page: 1,
                limit: 5,
            },
            urls: {
                actsInProgress: 'acts/in-progress',
                actsCompleted: 'acts/completed',
            },
        };
    },
    computed: {
        ...mapGetters('Bills', ['billsData']),
        ...mapGetters('User', ['userData']),
    },
    created() {
        this.getData();
    },
    methods: {
        ...mapActions('Bills', ['getTableData', 'getActFile']),
        handleSizeChange() {
            this.pagination.page = 1;
            this.getData();
        },
        handleCurrentChange() {
            this.getData();
        },
        getData() {
            const params = {
                type: this.component,
                url: this.urls[this.component],
                page: this.pagination.page,
                limit: this.pagination.limit,
            };
            this.getTableData(params);
        },
        rowExpandChange(e) {
            const ind = this.billsData[this.component].data.findIndex((item) => item.id === e.id);
            if (!this.billsData[this.component].data[ind].fileUrl) {
                this.getActFile({ type: this.component, ind, row: e }).then(() => {
                    this.$refs.table.store.states.lazyTreeNodeMap = {};
                });
            }
        },
        expandRow(e) {
            this.$refs.table.toggleRowExpansion(e.row);
        },
        expandButtonTitle(e) {
            return e.statusId === 2 && this.userData.user.sno === 'usn' && this.userData.user.edoAgreement === 1 ? 'Посмотреть и подписать' : 'Посмотреть';
        },
        expandButtonColor(e) {
            return e.statusId === 2 && this.userData.user.sno === 'usn' && this.userData.user.edoAgreement === 1 ? 'btn_red' : 'btn_dark';
        },
        showControls(statusId, fileUrl) {
            return statusId === 2 && fileUrl && this.userData.user.sno === 'usn' && this.userData.user.edoAgreement === 1;
        },
    },
};
</script>

<style scoped>
::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
    background-color: #FFFFFF;
}
</style>
