<template>
    <div>
        <div class="flex flex__jc-fe">
            <el-button class="btn_32 btn_red" @click="changeDialogVisible">
                Подписать
            </el-button>

            <el-button class="btn_32 btn_white" @click="showComment = !showComment">
                Отказаться
            </el-button>
        </div>

        <div v-if="showComment" class="flex flex__direction-c mt_32">
            <div class="relative mb_32">
                <el-input
                    v-model="comment"
                    :class="{'common__textarea-error': $v.comment.$error}"
                    :show-word-limit="true"
                    class="el-input__large el-custom_textarea"
                    maxlength="1000"
                    placeholder="Введите причину отказа"
                    rows="2"
                    type="textarea"
                />

                <p v-if="$v.comment.$error" class="text_12 text_red common__error-text">
                    Заполните поле
                </p>
            </div>

            <el-button :loading="loading" class="btn_32 btn_dark ml_auto" @click="reject">
                Отправить на корректировку
            </el-button>
        </div>

        <el-dialog
            :append-to-body="true"
            :show-close="false"
            :close-on-click-modal="false"
            :visible.sync="dialogVisible"
            custom-class="mp_dialog mp_dialog-small"
        >
            <div slot="title" class="el-dialog__header-inner">
                <h2>Подписание документа</h2>

                <img
                    alt="close"
                    class="mp__dialog-close cp"
                    src="/img/icons/dialog-close.svg"
                    @click="changeDialogVisible"
                >
            </div>

            <div class="el-dialog__body-inner">
                <p class="text_15 text_dark mb_24">
                    Подтвердите пожалуйта, что Вы ознакомились с актом и хотите его подписать
                </p>

                <div class="btn_fw-container">
                    <el-button class="btn_white btn_56" @click="changeDialogVisible">
                        Отмена
                    </el-button>

                    <el-button :loading="loading" class="btn_red btn_56" @click="sign">
                        Подписать
                    </el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators/index.js';
import { mapActions } from 'vuex';

export default {
    name: 'ActsControls',
    props: ['act'],
    data() {
        return {
            comment: '',
            showComment: false,
            dialogVisible: false,
            loading: false,
        };
    },
    validations: {
        comment: {
            required,
        },
    },
    methods: {
        ...mapActions('Bills', ['signAct', 'rejectAct']),
        sign() {
            this.loading = true;

            this.signAct({ id: this.act.id })
                .then(() => {
                    this.$notify.success({ title: 'Успешно', message: 'Акт успешно подписан' });
                    this.changeDialogVisible();
                    this.$emit('needUpdate');
                })
                .catch(() => {
                    this.$notify.error({ title: 'Ошибка', message: 'Что-то пошло не так' });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        reject() {
            this.$v.$touch();

            if (!this.$v.$invalid) {
                this.loading = true;

                this.rejectAct({ comment: this.comment, id: this.act.id })
                    .then(() => {
                        this.$notify.success({ title: 'Успешно', message: 'Акт отправлен на корректировку' });
                        this.$emit('needUpdate');
                    })
                    .catch(() => {
                        this.$notify.error({ title: 'Ошибка', message: 'Что-то пошло не так' });
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        changeDialogVisible() {
            this.dialogVisible = !this.dialogVisible;
        },
    },
};
</script>

<style scoped>

</style>
