<template>
    <div class="login-container">
        <div class="login-background" />
        <div class="login-form">
            <component :is="component" :component.sync="component" />
        </div>
    </div>
</template>

<script>
import LoginMain from '@/views/login/login-main.vue';
import LoginRestorePassword from '@/views/login/login-restore-password.vue';
import HeadTitle from '@/components/UI/head-title.vue';

export default {
    name: 'LoginView',
    components: { HeadTitle, LoginRestorePassword, LoginMain },
    data() {
        return {
            component: 'LoginMain',
        };
    },
};
</script>

<style scoped>

</style>
