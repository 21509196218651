<template>
    <div>
        <bills-table :component="component" />
    </div>
</template>

<script>
import BillsTable from '@/views/accounts/parts/bills-table.vue';

export default {
    name: 'BillsInProgress',
    components: { BillsTable },
    props: ['component'],
};
</script>

<style scoped>

</style>
