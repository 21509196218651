import { render, staticRenderFns } from "./LeadsView.vue?vue&type=template&id=4460bf33&scoped=true"
import script from "./LeadsView.vue?vue&type=script&lang=js"
export * from "./LeadsView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4460bf33",
  null
  
)

export default component.exports