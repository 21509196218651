<template>
    <div class="wrapper">
        <top-bar />

        <div class="container">
            <slot />
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import TopBar from '@/components/TopBar.vue';

export default {
    name: 'MainLayout',
    components: { TopBar },
    created() {
        this.getUser();
    },
    mounted() {
        this.initMetrics();
    },
    methods: {
        ...mapActions('User', ['getUser']),
        initMetrics() {
            (function (m, e, t, r, i, k, a) {
                m[i] = m[i] || function () {
                    // eslint-disable-next-line prefer-rest-params
                    (m[i].a = m[i].a || []).push(arguments);
                };
                m[i].l = 1 * new Date();
                // eslint-disable-next-line no-plusplus
                for (let j = 0; j < document.scripts.length; j++) {
                    if (document.scripts[j].src === r) { return; }
                }
                // eslint-disable-next-line prefer-destructuring,no-unused-expressions,no-sequences
                k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k, a);
            }(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym'));
            ym(96599606, 'init;', {
                clickmap: true,
                trackLinks: true,
                accurateTrackBounce: true,
                webvisor: true,
            });
        },
    },
};
</script>
<style scoped>
</style>
