import Vue from 'vue';
import axios from 'axios';

import {
    Dialog, Button, Autocomplete, DatePicker, TimeSelect, Checkbox, CheckboxGroup,
    Input, Progress, Radio, RadioGroup, Drawer, Menu, MenuItem, Collapse, CollapseItem,
    Select, Option, Tooltip, Skeleton, SkeletonItem, Dropdown, DropdownItem, DropdownMenu, Popover,
    Loading, Divider, Upload, Table, TableColumn, Pagination, RadioButton, Notification,
} from 'element-ui';
import lang from 'element-ui/lib/locale/lang/ru-RU';
import locale from 'element-ui/lib/locale';

import Vuelidate from 'vuelidate';

import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/main.scss';

import store from './store';
import router from './router';
import App from './App.vue';
import './services/filters.js';

locale.use(lang);
Vue.use(Dialog);
Vue.use(Button);
Vue.use(Autocomplete);
Vue.use(DatePicker);
Vue.use(TimeSelect);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Input);
Vue.use(Progress);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Drawer);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Select);
Vue.use(Option);
Vue.use(Tooltip);
Vue.use(Skeleton);
Vue.use(SkeletonItem);
Vue.use(Dropdown);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);
Vue.use(Popover);
Vue.use(Divider);
Vue.use(Upload);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(Loading.directive);

Vue.use(Vuelidate);

Vue.prototype.$notify = Notification;

Vue.config.productionTip = false;
axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? 'https://agents.testproportal.trosbank.trus.tsocgen' : '';

if (localStorage.getItem('token')) {
    axios.defaults.headers.common['api-token'] = localStorage.getItem('token');
}

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
