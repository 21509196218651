<template>
    <div class="relative">
        <div ref="mp_tabs" :data-testid="`prospect-${testid}-tab-container`" class="mp__tabs">
            <div
                v-for="item in tabs"
                :key="item.id"
                :ref="`mp-tab-${item.id}`"
                :data-testid="`prospect-${testid}-tab-item-${item.id}`"
                class="mp__tabs-item"
                @click="setActiveTab(item)"
            >
                <div :style="activeTabStyle(item)" class="text_13 text_gray text_b">
                    {{ item.name }}
                </div>
            </div>

            <div :style="activeBarStyle" class="mp__tabs__active-bar" />
        </div>

        <div v-if="!needDrag" class="mp__tabs-wl" />
    </div>
</template>

<script>

/*
* Передаем массив табов через prop 'tabs', вида [{id: 1, name: 'Таб'}, ...]
* При нажатии на таб, в родительский компонент пробрасывается событие с id таба
* Получаем в родителе, и например делаем видимым свой компонент через v-if
* Или используем <component :is=component>, используя в качестве id названия компонентов
* Пример можно посмотреть в проспектах мобильного портала.
* */

export default {
    name: 'MpTabs',
    props: {
        tabs: {
            type: Array,
            default() {
                return [{
                    id: 1,
                    name: 'Tab1',
                }, {
                    id: 2,
                    name: 'Tab2',
                }];
            },
        },
        testid: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            currentTab: null,
            activeBarPosition: {
                left: 0,
                width: 0,
            },
            needDrag: false,
            dragEl: null,
            pos: {
                top: 0,
                left: 0,
                x: 0,
                y: 0,
            },
            dragElSize: {
                clientWidth: 0,
                scrollWidth: 0,
            },
        };
    },
    computed: {
        /*
        * Меняем позицию и ширину подсветки активного таба
        * */
        activeBarStyle() {
            return {
                width: `${this.activeBarPosition.width}px`,
                transform: `translateX(${this.activeBarPosition.left}px)`,
            };
        },
    },
    watch: {
        /*
        * Определяем, помещаются ли все табы в ширину контейнера, если нет то делаем активным скролл на десктопе
        * */
        'dragElSize.clientWidth': function () {
            this.needDrag = this.dragElSize.scrollWidth > this.dragElSize.clientWidth;
            this.checkNeedDrag();
        },
    },
    mounted() {
        this.init();
        window.addEventListener('resize', this.checkDragElSize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkDragElSize);
    },
    methods: {
        init() {
            this.dragEl = this.$refs.mp_tabs;
            [this.currentTab] = this.tabs;
            this.setActiveBarPosition();
            this.checkDragElSize();
            this.checkNeedDrag();
        },
        setActiveTab(e) {
            this.currentTab = e;
            this.setActiveBarPosition();
            this.$emit('changeTab', e.id);
        },
        /*
        * Определяем ширину и позицию подсветки активного таба
        * */
        setActiveBarPosition() {
            const el = this.$refs[`mp-tab-${this.currentTab.id}`][0];
            this.activeBarPosition.left = el.offsetLeft;
            this.activeBarPosition.width = el.clientWidth;

            el.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            });
        },
        /*
        * Сохраняем позицию курсора и подписываемся на события перемещения курсора и отпускания кнопки мыши
        * */
        mouseDownHandler(e) {
            this.dragEl.style.cursor = 'grabbing';
            this.dragEl.style.userSelect = 'none';

            this.pos = {
                left: this.dragEl.scrollLeft,
                top: this.dragEl.scrollTop,
                // Get the current mouse position
                x: e.clientX,
                y: e.clientY,
            };

            window.addEventListener('mousemove', this.mouseMoveHandler);
            window.addEventListener('mouseup', this.mouseUpHandler);
        },
        /*
        * Отписываемся от событий и меняем стиль курсора
        * */
        mouseUpHandler() {
            this.dragEl.style.cursor = 'grab';
            this.dragEl.style.removeProperty('user-select');

            window.removeEventListener('mousemove', this.mouseMoveHandler);
            window.removeEventListener('mouseup', this.mouseUpHandler);
        },
        /*
       * Крутим скролл по перемещению мыши
       * */
        mouseMoveHandler(e) {
            const dx = e.clientX - this.pos.x;
            const dy = e.clientY - this.pos.y;

            this.dragEl.scrollTop = this.pos.top - dy;
            this.dragEl.scrollLeft = this.pos.left - dx;
        },
        /*
       * Крутим скролл колесиком мыши
       * */
        mouseWheel(e) {
            const toLeft = e.deltaY < 0 && this.dragEl.scrollLeft > 0;
            const toRight = e.deltaY > 0 && this.dragEl.scrollLeft < this.dragEl.scrollWidth - this.dragEl.clientWidth;

            if (toLeft || toRight) {
                e.preventDefault();
                this.dragEl.scrollLeft += e.deltaY;
            }
        },
        /*
        * В зависимости от значения needDrag осуществляем подписку на события десктопного скрола или отписку
        * */
        checkNeedDrag() {
            if (this.needDrag) {
                this.dragEl.addEventListener('mousedown', this.mouseDownHandler);
                this.dragEl.addEventListener('wheel', this.mouseWheel);
                this.dragEl.style.cursor = 'grab';
            } else {
                this.dragEl.removeEventListener('mousedown', this.mouseDownHandler);
                this.dragEl.removeEventListener('wheel', this.mouseWheel);
                this.dragEl.style.cursor = 'default';
            }
        },
        /*
        * Сохраняем размеры контейнера скрола
        * */
        checkDragElSize() {
            this.dragElSize.clientWidth = this.dragEl.clientWidth;
            this.dragElSize.scrollWidth = this.dragEl.scrollWidth;
        },
        /*
        * Цвет шрифта активного таба
        * */
        activeTabStyle(e) {
            return {
                color: this.currentTab?.id === e?.id ? '#000C1A' : '',
            };
        },

    },
};
</script>

<style lang="scss" scoped>
.mp__tabs {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    overflow-x: scroll;
    height: 64px;
    border-bottom: 1px solid #D5D9DF;
    margin-right: -24px;

    > div:last-child {
        margin-right: 0;
    }

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    &::-webkit-scrollbar-button {
        width: 0;
        height: 0;
    }
}

.mp__tabs-item {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    height: 100%;
    margin-right: 24px;
    user-select: none;
    cursor: pointer;
}

.mp__tabs__active-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    background-color: #E40038;
    z-index: 2;
    transition: transform .3s cubic-bezier(.645, .045, .355, 1);
    list-style: none;
}

.mp__tabs-wl {
    position: absolute;
    bottom: 0;
    width: 24px;
    height: 1px;
    background-color: #ffffff;
    right: -24px;
    z-index: 5;
}
</style>
