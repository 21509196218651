<template>
    <div>
        <h1 class="mb_48">
            Авторизация<br>в личном кабинете
        </h1>

        <div class="relative mb_24">
            <el-input
                v-model.trim="user.email"
                :disabled="authData.loginSuccess"
                :class="{'common__input-error': $v.user.email.$error}"
                class="el-input__large"
                placeholder="E-mail"
            />

            <p v-if="user.email" class="text_12 text_gray input-large__label">
                E-mail
            </p>

            <p v-if="$v.user.email.$error" class="text_12 text_red common__error-text">
                Неверный формат
            </p>
        </div>

        <div class="relative mb_24">
            <el-input
                v-model.trim="user.password"
                type="password"
                :disabled="authData.loginSuccess"
                :class="{'common__input-error': $v.user.password.$error}"
                class="el-input__large"
                placeholder="Пароль"
                :show-password="true"
            />

            <p v-if="user.password" class="text_12 text_gray input-large__label">
                Пароль
            </p>

            <p v-if="loginErrorText" class="text_12 text_red common__error-text">
                {{ loginErrorText }}
            </p>
        </div>

        <div v-if="authData.loginSuccess" class="relative">
            <el-input
                v-model.trim="user.code"
                :class="{'common__input-error': $v.user.code.$error}"
                class="el-input__large"
                placeholder="Код"
            />

            <p v-if="user.code" class="text_12 text_gray input-large__label">
                Код
            </p>

            <p v-if="codeErrorText" class="text_12 text_red mt_4 ml_12">
                {{ codeErrorText }}
            </p>

            <p class="flex flex__ai-c text_13 text_gray mt_4 ml_12">
                {{ codeRefreshTitle }}

                <img
                    v-if="!seconds"
                    :class="rotate"
                    class="ml_6 cp"
                    height="16px"
                    src="/img/icons/refresh.svg"
                    alt=""
                    @click="getCode"
                >
            </p>
        </div>

        <div class="flex flex__ai-c mt_32">
            <el-button v-if="authData.loginSuccess" class="btn_white btn_56" @click="clearForm">
                Отмена
            </el-button>

            <el-button :loading="loginButtonLoading" class="btn_red btn_56" @click="loginAction">
                {{ buttonTitle }}
            </el-button>

            <p v-if="!authData.loginSuccess" class="text_15 text_red ml_16 cp" @click="goToPassRestore">
                Забыли пароль?
            </p>
        </div>
    </div>
</template>

<script>
import { email, required, requiredUnless } from 'vuelidate/lib/validators/index.js';
import { mapActions, mapGetters, mapMutations } from 'vuex';

function User() {
    this.email = '';
    this.password = null;
    this.code = null;
}

export default {
    name: 'LoginMain',
    data() {
        return {
            user: null,
            loginTime: null,
            currentTime: null,
            seconds: null,
            timer: null,
            getCodeLoading: false,
            checkCodeLoading: false,
        };
    },
    validations: {
        user: {
            email: {
                email,
                required,
            },
            password: {
                required,
            },
            code: {
                required: requiredUnless(function () {
                    return !this.authData.loginSuccess;
                }),
            },
        },
    },
    computed: {
        ...mapGetters('User', ['authData']),
        buttonTitle() {
            return this.authData.loginSuccess ? 'Авторизоваться' : 'Вход';
        },
        loginErrorText() {
            let text = null;

            if (this.authData.loginError) {
                text = 'Неверный логин или пароль';
            }

            if (this.$v.user.password.$error) {
                text = 'Заполните поле';
            }

            return text;
        },
        codeErrorText() {
            let text = null;

            if (this.authData.codeError) {
                text = 'Неверный код подтверждения';
            }

            if (this.$v.user.code.$error) {
                text = 'Заполните поле';
            }

            return text;
        },
        loginButtonLoading() {
            return (this.getCodeLoading && !this.authData.loginSuccess) || this.checkCodeLoading;
        },
        codeRefreshTitle() {
            return this.seconds > 0
                ? `Вам был отправлен sms с кодом подтверждения. Отправить повторно через ${this.seconds} сек.`
                : 'Отправить код повторно';
        },
        rotate() {
            return {
                'icon-rotate': this.getCodeLoading,
            };
        },
    },
    created() {
        this.user = new User();
    },
    beforeDestroy() {
        clearInterval(this.timer);
    },
    methods: {
        ...mapActions('User', ['login', 'checkCode']),
        ...mapMutations('User', ['refreshLoginSuccess']),
        startTimer() {
            this.timer = setInterval(() => {
                if (this.seconds) {
                    this.seconds -= 1;
                } else {
                    clearInterval(this.timer);
                }
            }, 1000);
        },
        getCode() {
            this.getCodeLoading = true;

            this.login(this.user)
                .then(() => {
                    this.seconds = 59;
                    this.startTimer();
                    this.$v.$reset();
                })
                .finally(() => {
                    this.getCodeLoading = false;
                });
        },
        loginAction() {
            if (!this.authData.loginSuccess) {
                this.$v.$touch();

                if (!this.$v.$invalid) {
                    this.getCode();
                }
            } else {
                this.$v.$touch();

                if (!this.$v.$invalid) {
                    this.checkCodeLoading = true;

                    this.checkCode(this.user)
                        .then(() => {
                            this.$router.push('/main');
                        })
                        .catch((err) => {
                            console.log(err);
                        })
                        .finally(() => {
                            this.checkCodeLoading = false;
                        });
                }
            }
        },
        clearForm() {
            this.user = new User();
            this.refreshLoginSuccess();
        },
        goToPassRestore() {
            this.$emit('update:component', 'LoginRestorePassword');
        },
    },
};
</script>

<style scoped>

</style>
