import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import customParseFormat from 'dayjs/plugin/customParseFormat.js';
import localizedFormat from 'dayjs/plugin/localizedFormat.js';
import utc from 'dayjs/plugin/utc.js';

dayjs.locale('ru');
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(customParseFormat);
Vue.prototype.$dayjs = dayjs;

Vue.filter('date', (value) => {
    if (!value) return value;
    return dayjs(String(value)).format('L');
});
