<template>
    <div v-loading="loading" class="flex flex__ai-c">
        <el-dropdown
            v-if="userData.user"
            class="mr_32"
            placement="bottom-start"
            trigger="click"
            @command="action"
        >
            <p class="flex flex__ai-c text_15 text_dark cp">
                {{ getUserName }}<i class="el-icon-arrow-down ml_6" />
            </p>

            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="change-password">
                    Сменить пароль
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>

        <img
            class="cp"
            src="/img/icons/logout.svg"
            alt=""
            @click="setLogout"
        >

        <el-dialog
            :append-to-body="true"
            :show-close="false"
            :close-on-click-modal="false"
            :visible.sync="dialogVisible"
            custom-class="mp_dialog mp_dialog-small"
        >
            <div slot="title" class="el-dialog__header-inner">
                <h2>Смена пароля</h2>

                <img
                    alt="close"
                    class="mp__dialog-close cp"
                    src="/img/icons/dialog-close.svg"
                    @click="changeDialogVisible"
                >
            </div>

            <div class="el-dialog__body-inner">
                <div class="relative mb_24">
                    <el-input
                        v-model.trim="password"
                        type="password"
                        class="el-input__large"
                        placeholder="Новый пароль"
                    />

                    <p v-if="password" class="text_12 text_gray input-large__label">
                        Новый пароль
                    </p>
                </div>

                <div class="btn_fw-container">
                    <el-button class="btn_white btn_56" @click="changeDialogVisible">
                        Отмена
                    </el-button>

                    <el-button :loading="passwordSaveLoading" class="btn_red btn_56" @click="save">
                        Изменить
                    </el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'TopBarUserControls',
    data() {
        return {
            password: null,
            dialogVisible: false,
            loading: false,
            passwordSaveLoading: false,
        };
    },
    computed: {
        ...mapGetters('User', ['userData']),
        getUserName() {
            const initials = this.userData.user?.firstName ? this.userData.user?.firstName.split(/\s+/).map((w, i) => (`${w.substring(0, 1).toUpperCase()}.`)).join('') : '';
            return `${this.userData.user?.lastName} ${this.userData.user?.firstName}`;
        },
    },
    methods: {
        ...mapActions('User', ['logout', 'changePassword']),
        setLogout() {
            this.loading = true;
            this.logout()
                .then(() => {
                    this.$router.push('/login');
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        action(e) {
            if (e === 'change-password') {
                this.changeDialogVisible();
            }
        },
        changeDialogVisible() {
            this.dialogVisible = !this.dialogVisible;
        },
        save() {
            if (this.password) {
                this.passwordSaveLoading = true;

                this.changePassword({ password: this.password })
                    .then(() => {
                        this.dialogVisible = false;
                        this.$notify.success({ title: 'Успешно', message: 'Пароль изменен' });
                        this.password = null;
                    })
                    .catch(() => {
                        this.$notify.error({ title: 'Ошибка', message: 'Что-то пошло не так' });
                    })
                    .finally(() => {
                        this.passwordSaveLoading = false;
                    });
            }
        },
    },
};
</script>

<style scoped>

</style>
