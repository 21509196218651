<template>
    <div>
        <h1 class="mb_48">
            Сброс пароля
        </h1>

        <div class="relative mb_24">
            <el-input
                v-model.trim="email"
                :class="{'common__input-error': $v.email.$error}"
                class="el-input__large"
                placeholder="E-mail"
            />

            <p class="text_13 text_gray mt_4 ml_12">
                На указанный e-mail будет отправлен временный пароль
            </p>

            <p v-if="email" class="text_12 text_gray input-large__label">
                E-mail
            </p>

            <p v-if="$v.email.$error" class="text_12 text_red common__error-text">
                Неверный формат
            </p>
        </div>

        <el-button class="btn_white btn_56" @click="goToLogin">
            Отмена
        </el-button>

        <el-button :loading="loading" class="btn_red btn_56" @click="restorePassword">
            Сбросить пароль
        </el-button>

        <el-dialog
            :append-to-body="true"
            :show-close="false"
            :visible.sync="dialogVisible"
            custom-class="mp_dialog mp_dialog-small"
        >
            <div class="el-dialog__body-inner__no-header">
                <p class="text_15 text_dark mb_24">
                    {{ message }}
                </p>

                <el-button class="btn_red btn_56" @click="goToLogin">
                    Закрыть
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { email, required } from 'vuelidate/lib/validators/index.js';
import { mapActions } from 'vuex';

export default {
    name: 'LoginRestorePassword',
    data() {
        return {
            message: null,
            email: null,
            dialogVisible: false,
            loading: false,
        };
    },
    validations: {
        email: {
            email,
            required,
        },
    },
    methods: {
        ...mapActions('User', ['resetPassword']),
        goToLogin() {
            this.$emit('update:component', 'LoginMain');
        },
        restorePassword() {
            this.$v.$touch();

            if (!this.$v.$invalid) {
                this.loading = true;

                this.resetPassword({ email: this.email })
                    .then(() => {
                        this.email = null;
                        this.$v.$reset();
                        this.message = 'Временный пароль был отправлен на указанный вами e-mail.';
                    })
                    .catch((err) => {
                        this.message = err;
                    })
                    .finally(() => {
                        this.dialogVisible = true;
                        this.loading = false;
                    });
            }
        },
    },
};
</script>

<style scoped>

</style>
