<template>
    <div class="flex flex__ai-c">
        <p class="text_15 text_gray">
            c {{ leadsData.period.from | date }} по {{ leadsData.period.to | date }}
        </p>

        <el-date-picker
            v-model="dateRange"
            :clearable="false"
            :editable="false"
            :picker-options="pickerOptions"
            range-separator=""
            align="center"
            class="el-input__small el-date__button mp-input__cp ml_16"
            format="dd.MM.yyyy"
            placeholder="Дата"
            popper-class="el-date__button__popper"
            prefix-icon="calendar-input-icon"
            type="daterange"
            @change="setRange"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import dayjs from 'dayjs';

export default {
    name: 'LeadsPeriodSelect',
    data() {
        return {
            dateRange: [],
            pickerOptions: {
                firstDayOfWeek: 1,
                disabledDate(time) {
                    return time.getTime() > dayjs()
                        .subtract(0, 'd');
                },
            },
        };
    },
    computed: {
        ...mapGetters('Leads', ['leadsData']),
    },
    created() {
        this.setPeriod(null);
        this.dateRange[0] = this.leadsData.period.from;
        this.dateRange[1] = this.leadsData.period.to;
    },
    methods: {
        ...mapActions('Leads', ['setPeriod']),
        setRange(e) {
            this.setPeriod(this.dateRange);

            this.$emit('needUpdate');
        },
    },
};
</script>

<style scoped>

</style>
