<template>
    <div class="mb_32">
        <div v-if="!leadsData.summary.loading && leadsData.summary.data" class="leads-summary">
            <div v-for="(item, ind) in items" :key="ind">
                <div class="mt_auto">
                    <h2 class="mb_6">
                        {{ getTitle(item.type) }}
                    </h2>

                    <p class="text_15 text_gray">
                        {{ item.text }}
                    </p>
                </div>
            </div>
        </div>

        <el-skeleton
            v-else
            :count="1"
            :rows="4"
            animated
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moneyFormat from '@/services/moneyFormat.js';

export default {
    name: 'LeadsSummary',
    data() {
        return {
            items: [
                {
                    text: 'заведено за период',
                    type: 'leads_come',
                },
                {
                    text: 'привлечено',
                    type: 'complete_leads',
                },
                {
                    text: 'доход за период',
                    type: 'money',
                },
            ],
        };
    },
    computed: {
        ...mapGetters('Leads', ['leadsData']),
    },
    methods: {
        getTitle(type) {
            return type !== 'money' ? this.leadsData.summary.data[type] : moneyFormat(this.leadsData.summary.data[type]);
        },
    },
};
</script>

<style scoped lang="scss">
.leads-summary {
    display: flex;

    > div {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 140px;
        padding: 24px;
        border-radius: 12px;
        background: #F2F3F4;
    }

    > div:not(:last-child) {
        margin-right: 24px;
    }
}
</style>
