/* eslint-disable no-shadow */

import axios from 'axios';

const state = {
    requisitesData: {
        list: [],
        loading: false,
        saveLoading: false,
    },
};

const getters = {
    requisitesData: () => state.requisitesData,
};

const actions = {
    /**
     *  Получение списка версий реквизитов
     */
    getRequisites({ commit }) {
        commit('changeLoading', 'loading');

        return axios.get('/api/requisites')
            .then((resp) => {
                commit('getRequisites', resp.data);
                return Promise.resolve();
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                commit('changeLoading', 'loading');
            });
    },

    /**
     *  Добавление новой версии реквизитов
     */
    createRequisites({ commit, state }, params) {
        commit('changeLoading', 'saveLoading');

        return axios.post('/api/requisites/create', params)
            .then((resp) => Promise.resolve())
            .catch((err) => Promise.reject(err))
            .finally(() => commit('changeLoading', 'saveLoading'));
    },
};

const mutations = {
    changeLoading(state, payload) {
        state.requisitesData[payload] = !state.requisitesData[payload];
    },

    getRequisites(state, payload) {
        state.requisitesData.list = payload.data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
