<template>
    <div class="r_form">
        <h1 style="position: absolute">
            Реквизиты
        </h1>

        <component
            :is="component"
            v-if="!requisitesData.loading"
            :ref="component"
            @setEditMode="setEditMode"
            @setViewMode="setViewMode"
            @updateList="updateList"
        />

        <el-skeleton
            v-else
            style="padding-top: 72px"
            :count="1"
            :rows="14"
            animated
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import RequisitesForm from '@/views/requisites/requisites-form.vue';
import RequisitesEdit from '@/views/requisites/requisites-edit.vue';
import RequisitesList from '@/views/requisites/requisites-list.vue';

export default {
    name: 'RequisitesView',
    components: { RequisitesList, RequisitesEdit, RequisitesForm },
    data() {
        return {
            component: '',
            disabled: false,
        };
    },
    computed: {
        ...mapGetters('Requisites', ['requisitesData']),
    },
    created() {
        this.getRequisitesList();
    },
    methods: {
        ...mapActions('Requisites', ['getRequisites']),
        getRequisitesList() {
            this.getRequisites()
                .then(() => {
                    if (this.requisitesData.list.length) {
                        this.setViewMode();
                    } else {
                        this.setEditMode();
                    }
                });
        },
        setEditMode() {
            this.component = 'RequisitesEdit';
        },
        setViewMode() {
            this.component = 'RequisitesList';
        },
        updateList() {
            this.getRequisitesList();
        },
    },
};
</script>

<style scoped>
.r_form {
    width: 100%;
    max-width: 670px;
}
</style>
